import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import React, { useState } from "react";
import { navigate } from "gatsby";
import { Route } from "@interfaces";
import { CreditTranslator1, CreditTranslator2, SEO } from "@components";
import { useAuth } from "@hooks";

const CreditSimulatorView = () => {
  useAuth({ redirectIfNotLogged: true });

  const [page, setPage] = useState(1);
  const [term, setTerm] = useState(12);
  const [cae, setCae] = useState(30);
  const [rate, setRate] = useState(0);
  const [amount, setAmount] = useState(0);
  const [monthPayment, setMonthPayment] = useState(0);

  return (
    <>
      <SEO />
      <LayoutBudget
        title="Traductor de crédito"
        onTurnBackClick={() => navigate(Route.rokipedia+ "/?#tools")}
      >
        {page == 1 ? (
          <CreditTranslator1
            setPage={setPage}
            term={term}
            setTerm={setTerm}
            cae={cae}
            setCae={setCae}
            rate={rate}
            setRate={setRate}
            amount={amount}
            setAmount={setAmount}
            setMonthPayment={setMonthPayment}
          />
        ) : (
          <CreditTranslator2
            setPage={setPage}
            monthPayment={monthPayment}
            cae={cae}
            term={term}
          />
        )}
      </LayoutBudget>
    </>
  );
};

export default CreditSimulatorView;
